import React, { useRef, useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "images/server-illustration-2.svg";
import logo from "images/logo_stackbyte.svg";
import googleIconImageSrc from "images/google-icon.png";
import twitterIconImageSrc from "images/twitter-icon.png";
import { ReactComponent as SignUpIcon } from "feather-icons/dist/icons/user-plus.svg";
import { ReactComponent as AlertIcon } from "feather-icons/dist/icons/alert-triangle.svg";
import { useHistory } from "react-router-dom";
import { requestServer, checkBlur, getData, storeData } from '../services/request'
import Select from 'react-select'

const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-9/12 p-6 sm:p-12`;
const LogoLink = tw.a`cursor-pointer`;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

// const SocialButtonsContainer = tw.div`flex flex-col items-center`;
// const SocialButton = styled.a`
//   ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
//   .iconContainer {
//     ${tw`bg-white p-2 rounded-full`}
//   }
//   .icon {
//     ${tw`w-4`}
//   }
//   .text {
//     ${tw`ml-4`}
//   }
// `;

// const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const Para = tw.p`mt-20 mb-2 text-center first:mt-0`;
// const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const Grid = tw.div`grid grid-cols-1 sm:grid-cols-2 gap-3`;
const Warn = tw.p`text-red-500 justify-end flex p-2 bg-red-100 mt--1 rounded-b-md`;
const Warnmail = tw.p`absolute right-0 top-0 text-red-500 justify-end flex pt-1 mt-8 mr-2 rounded-t-md`;
const Spinner = tw.span`animate-pulse w-5 h-5 border-white rounded-full bg-white`;
// const Form = tw.form`mx-auto`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const ProcessButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-300 text-gray-100 w-full py-4 rounded-lg transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
  disabled
`;
// const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
// const IllustrationImage = styled.div`
//   ${props => `background-image: url("${props.imageSrc}");`}
//   ${tw`m-12 xl:m-16 w-full max-w-lg bg-contain bg-center bg-no-repeat`}
// `;

export default ({
  logoLinkUrl = "/",
  illustrationImageSrc = illustration,
  headingText = "Join Stackbyte",
  socialButtons = [
    {
      iconImageSrc: googleIconImageSrc,
      text: "Sign Up With Google",
      url: "https://google.com"
    },
    {
      iconImageSrc: twitterIconImageSrc,
      text: "Sign Up With Twitter",
      url: "https://twitter.com"
    }
  ],
  submitButtonText = "Register",
  SubmitButtonIcon = SignUpIcon,
  WarnIcon = AlertIcon,
  tosUrl = "#",
  privacyPolicyUrl = "#",
  signInUrl = "#"
}) => {

  const [payload, setPayload] = useState({
    api_name: "registration",
    data: {
      groupName: "",
      primaryEmail: "",
      primaryContact: "",
      amount: getData('price'),
      membershipPlan: getData('price') < 240000 ? getData('price') < 120000 ? "megabyte" : "gigabyte" : "terabyte",
      createdBy: "1",
      topPreference: [],
      env: "live",
      teamDetails: [
        {
          groupName: "",
          fullName: "",
          email: "",
          course: "",
          branch: "",
          year: "",
          collegeName: "",
          mobile_no: "",
          city: "",
          state: "",
          country: "",
          createdBy: "1"
        },
        {
          groupName: "",
          fullName: "",
          email: "",
          course: "",
          branch: "",
          year: "",
          collegeName: "",
          mobile_no: "",
          city: "",
          state: "",
          country: "",
          createdBy: "1"
        },
        {
          groupName: "",
          fullName: "",
          email: "",
          course: "",
          branch: "",
          year: "",
          collegeName: "",
          mobile_no: "",
          city: "",
          state: "",
          country: "",
          createdBy: "1"
        },
        {
          groupName: "",
          fullName: "",
          email: "",
          course: "",
          branch: "",
          year: "",
          collegeName: "",
          mobile_no: "",
          city: "",
          state: "",
          country: "",
          createdBy: "1"
        },
        {
          groupName: "",
          fullName: "",
          email: "",
          course: "",
          branch: "",
          year: "",
          collegeName: "",
          mobile_no: "",
          city: "",
          state: "",
          country: "",
          createdBy: "1"
        },
      ]
    }
  })

  const [process, setProcess] = useState(true)
  const [loading, setLoading] = useState(false);

  const register = () => {
    setProcess(false)
    requestServer(payload).then((res) => {
      setProcess(true)
      console.log(res.data);
      if (res.data.status) {
        history.push('/checkout/' + res.data.data.orderID)
        storeData('userData', res.data.data)
      } else {
        alert(res.data.message)
      }
    }).catch((err) => {
      setProcess(true)
      console.log(err)
    })
  }

  const [options, setOptions] = useState([])

  // const sake = [
  //   { value: 'chocolate', label: 'Chocolate' }
  // ]

  const listPreference = () => {
    const load = {
      "api_name": "getAllPreferences"
    }

    requestServer(load).then((res) => {
      setLoading(true)
      // setProcess(true)
      const data = res.data.data.map(dt => { return { "value": dt.preferenceName, "label": dt.preferenceName } })
      setOptions(data)
      setLoading(false)
      // console.log(data);
    }).catch((err) => {
      // setProcess(true)
      console.log(err)
    })
  }

  const [warned, setWarned] = useState("")
  const blur = (e) => {
    checkBlur(payload.data.groupName).then((res) => {
      if (!res.data.status) {
        console.log(res.data);
        setWarned(res.data.message)
      } else {
        setWarned("")
      }
    }).catch((err) => console.log(err))
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    listPreference()
  }, [])

  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      storeData('formData', payload)
    } else {
      isMounted.current = true;
    }
  }, [payload])

  useEffect(() => {
    if (getData('formData'))
      setPayload(getData('formData'))
  }, [])

  const [pemail, setPemail] = useState(true)
  const [email1, setEmail1] = useState(true)
  const [email2, setEmail2] = useState(true)
  const [email3, setEmail3] = useState(true)
  const [email4, setEmail4] = useState(true)
  const [email5, setEmail5] = useState(true)

  const [highlight, setHighlight] = useState(false)

  //eslint-disable-next-line
  const mailCheck = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  let history = useHistory();

  return (
    <AnimationRevealPage>
      <Container>
        <Content>
          {/* <MainContainer>
          
        </MainContainer> */}
          {loading ? <div style={{ display: 'flex', justifyContent: 'center', margin: 'mx-auto' }}>
            <div className="loader">
            </div>
          </div> :
            <MainContainer>
              <LogoLink onClick={() => history.push(logoLinkUrl)}>
                <LogoImage src={logo} />
              </LogoLink>
              <MainContent>
                <Heading>{headingText}</Heading>
                <FormContainer>
                  <Input css={[highlight && (payload.data.groupName === "" && tw`border-red-600 border-2`)]} type="text" onBlur={blur} placeholder="Group name" value={payload.data.groupName} onChange={(ch) => { let chx = (ch.target.value).toUpperCase(); setPayload((prev) => ({ ...prev, data: { ...prev.data, groupName: chx, teamDetails: [{ ...prev.data.teamDetails[0], groupName: chx }, { ...prev.data.teamDetails[1], groupName: chx }, { ...prev.data.teamDetails[2], groupName: chx }, { ...prev.data.teamDetails[3], groupName: chx }, { ...prev.data.teamDetails[4], groupName: chx }] } })) }} />
                  {warned && <Warn>{warned} &nbsp; <WarnIcon className="icon" /></Warn>}
                  <div tw="relative">
                    <Input css={[highlight && (payload.data.primaryEmail === "" && tw`border-red-600 border-2`)]} type="email" onBlur={(ch) => { let chx = ch.target.value; setPemail(mailCheck.test(chx)) }} placeholder="Primary email" value={payload.data.primaryEmail} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, primaryEmail: chx, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4] }] } })) }} />
                    {!pemail && <Warnmail><WarnIcon className="icon" /></Warnmail>}
                  </div>
                  <Input css={[highlight && (payload.data.primaryContact === "" && tw`border-red-600 border-2`)]} type="tel" placeholder="Primary Phone no." value={payload.data.primaryContact} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, primaryContact: chx, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4] }] } })) }} />
                  <div css={[highlight && (payload.data.topPreference.length === 0 && tw`border-red-600 border-2`)]} tw="mt-5 rounded-lg">
                    <Select value={payload.data.topPreference.map((dt) => { return { "value": dt, "label": dt } })} options={options} isMulti="true" placeholder="Select Top Preferences" onChange={(ch) => { let chx = ch !== null ? ch.map(d => d.value) : []; setPayload((prev) => ({ ...prev, data: { ...prev.data, topPreference: chx, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4] }] } })) }} />
                  </div>
                </FormContainer>
              </MainContent>
              <MainContent>
                <Heading>Fill All Member's Details</Heading>
                <FormContainer>
                  <Para>Member 1</Para>
                  <Grid>
                    <Input css={[highlight && (payload.data.teamDetails[0].fullName === "" && tw`border-red-600 border-2`)]} type="text" placeholder="Fullname" value={payload.data.teamDetails[0].fullName} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0], fullName: chx }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4] }] } })) }} />
                    <div tw="relative">
                      <Input css={[highlight && (payload.data.teamDetails[0].email === "" && tw`border-red-600 border-2`)]} type="email" onBlur={(ch) => { let chx = ch.target.value; setEmail1(mailCheck.test(chx)) }} placeholder="Email" value={payload.data.teamDetails[0].email} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0], email: chx }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4] }] } })) }} />
                      {!email1 && <Warnmail><WarnIcon className="icon" /></Warnmail>}
                    </div>
                    <Input css={[highlight && (payload.data.teamDetails[0].year === "" && tw`border-red-600 border-2`)]} type="text" placeholder="Year" value={payload.data.teamDetails[0].year} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0], year: chx }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4] }] } })) }} />
                    <Input css={[highlight && (payload.data.teamDetails[0].course === "" && tw`border-red-600 border-2`)]} type="text" placeholder="Course" value={payload.data.teamDetails[0].course} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0], course: chx }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4] }] } })) }} />
                    <Input css={[highlight && (payload.data.teamDetails[0].branch === "" && tw`border-red-600 border-2`)]} type="text" placeholder="Branch" value={payload.data.teamDetails[0].branch} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0], branch: chx }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4] }] } })) }} />
                    <Input css={[highlight && (payload.data.teamDetails[0].collegeName === "" && tw`border-red-600 border-2`)]} type="text" placeholder="College Name" value={payload.data.teamDetails[0].collegeName} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0], collegeName: chx }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4] }] } })) }} />
                    <Input css={[highlight && (payload.data.teamDetails[0].mobile_no === "" && tw`border-red-600 border-2`)]} type="number" placeholder="Mobile No." value={payload.data.teamDetails[0].mobile_no} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0], mobile_no: chx }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4] }] } })) }} />
                    <Input css={[highlight && (payload.data.teamDetails[0].city === "" && tw`border-red-600 border-2`)]} type="text" placeholder="City" value={payload.data.teamDetails[0].city} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0], city: chx }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4] }] } })) }} />
                    <Input css={[highlight && (payload.data.teamDetails[0].state === "" && tw`border-red-600 border-2`)]} type="text" placeholder="State" value={payload.data.teamDetails[0].state} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0], state: chx }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4] }] } })) }} />
                    <Input css={[highlight && (payload.data.teamDetails[0].country === "" && tw`border-red-600 border-2`)]} type="text" placeholder="Country" value={payload.data.teamDetails[0].country} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0], country: chx }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4] }] } })) }} />
                  </Grid>
                  <Para>Member 2</Para>
                  <Grid>
                    <Input css={[highlight && (payload.data.teamDetails[1].fullName === "" && tw`border-red-600 border-2`)]} type="text" placeholder="Fullname" value={payload.data.teamDetails[1].fullName} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1], fullName: chx }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4] }] } })) }} />
                    <div tw="relative">
                      <Input css={[highlight && (payload.data.teamDetails[1].email === "" && tw`border-red-600 border-2`)]} type="email" onBlur={(ch) => { let chx = ch.target.value; setEmail2(mailCheck.test(chx)) }} placeholder="Email" value={payload.data.teamDetails[1].email} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1], email: chx }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4] }] } })) }} />
                      {!email2 && <Warnmail><WarnIcon className="icon" /></Warnmail>}
                    </div>
                    <Input css={[highlight && (payload.data.teamDetails[1].year === "" && tw`border-red-600 border-2`)]} type="text" placeholder="Year" value={payload.data.teamDetails[1].year} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1], year: chx }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4] }] } })) }} />
                    <Input css={[highlight && (payload.data.teamDetails[1].course === "" && tw`border-red-600 border-2`)]} type="text" placeholder="Course" value={payload.data.teamDetails[1].course} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1], course: chx }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4] }] } })) }} />
                    <Input css={[highlight && (payload.data.teamDetails[1].branch === "" && tw`border-red-600 border-2`)]} type="text" placeholder="Branch" value={payload.data.teamDetails[1].branch} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1], branch: chx }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4] }] } })) }} />
                    <Input css={[highlight && (payload.data.teamDetails[1].collegeName === "" && tw`border-red-600 border-2`)]} type="text" placeholder="College Name" value={payload.data.teamDetails[1].collegeName} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1], collegeName: chx }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4] }] } })) }} />
                    <Input css={[highlight && (payload.data.teamDetails[1].mobile_no === "" && tw`border-red-600 border-2`)]} type="number" placeholder="Mobile No." value={payload.data.teamDetails[1].mobile_no} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1], mobile_no: chx }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4] }] } })) }} />
                    <Input css={[highlight && (payload.data.teamDetails[1].city === "" && tw`border-red-600 border-2`)]} type="text" placeholder="City" value={payload.data.teamDetails[1].city} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1], city: chx }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4] }] } })) }} />
                    <Input css={[highlight && (payload.data.teamDetails[1].state === "" && tw`border-red-600 border-2`)]} type="text" placeholder="State" value={payload.data.teamDetails[1].state} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1], state: chx }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4] }] } })) }} />
                    <Input css={[highlight && (payload.data.teamDetails[1].country === "" && tw`border-red-600 border-2`)]} type="text" placeholder="Country" value={payload.data.teamDetails[1].country} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1], country: chx }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4] }] } })) }} />
                  </Grid>
                  <Para>Member 3</Para>
                  <Grid>
                    <Input css={[highlight && (payload.data.teamDetails[2].fullName === "" && tw`border-red-600 border-2`)]} type="text" placeholder="Fullname" value={payload.data.teamDetails[2].fullName} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2], fullName: chx }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4] }] } })) }} />
                    <div tw="relative">
                      <Input css={[highlight && (payload.data.teamDetails[2].email === "" && tw`border-red-600 border-2`)]} type="email" onBlur={(ch) => { let chx = ch.target.value; setEmail3(mailCheck.test(chx)) }} placeholder="Email" value={payload.data.teamDetails[2].email} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2], email: chx }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4] }] } })) }} />
                      {!email3 && <Warnmail><WarnIcon className="icon" /></Warnmail>}
                    </div>
                    <Input css={[highlight && (payload.data.teamDetails[2].year === "" && tw`border-red-600 border-2`)]} type="text" placeholder="Year" value={payload.data.teamDetails[2].year} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2], year: chx }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4] }] } })) }} />
                    <Input css={[highlight && (payload.data.teamDetails[2].course === "" && tw`border-red-600 border-2`)]} type="text" placeholder="Course" value={payload.data.teamDetails[2].course} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2], course: chx }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4] }] } })) }} />
                    <Input css={[highlight && (payload.data.teamDetails[2].branch === "" && tw`border-red-600 border-2`)]} type="text" placeholder="Branch" value={payload.data.teamDetails[2].branch} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2], branch: chx }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4] }] } })) }} />
                    <Input css={[highlight && (payload.data.teamDetails[2].collegeName === "" && tw`border-red-600 border-2`)]} type="text" placeholder="College Name" value={payload.data.teamDetails[2].collegeName} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2], collegeName: chx }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4] }] } })) }} />
                    <Input css={[highlight && (payload.data.teamDetails[2].mobile_no === "" && tw`border-red-600 border-2`)]} type="number" placeholder="Mobile No." value={payload.data.teamDetails[2].mobile_no} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2], mobile_no: chx }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4] }] } })) }} />
                    <Input css={[highlight && (payload.data.teamDetails[2].city === "" && tw`border-red-600 border-2`)]} type="text" placeholder="City" value={payload.data.teamDetails[2].city} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2], city: chx }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4] }] } })) }} />
                    <Input css={[highlight && (payload.data.teamDetails[2].state === "" && tw`border-red-600 border-2`)]} type="text" placeholder="State" value={payload.data.teamDetails[2].state} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2], state: chx }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4] }] } })) }} />
                    <Input css={[highlight && (payload.data.teamDetails[2].country === "" && tw`border-red-600 border-2`)]} type="text" placeholder="Country" value={payload.data.teamDetails[2].country} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2], country: chx }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4] }] } })) }} />
                  </Grid>
                  <Para>Member 4</Para>
                  <Grid>
                    <Input css={[highlight && (payload.data.teamDetails[3].fullName === "" && tw`border-red-600 border-2`)]} type="text" placeholder="Fullname" value={payload.data.teamDetails[3].fullName} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3], fullName: chx }, { ...prev.data.teamDetails[4] }] } })) }} />
                    <div tw="relative">
                      <Input css={[highlight && (payload.data.teamDetails[3].email === "" && tw`border-red-600 border-2`)]} type="email" onBlur={(ch) => { let chx = ch.target.value; setEmail4(mailCheck.test(chx)) }} placeholder="Email" value={payload.data.teamDetails[3].email} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3], email: chx }, { ...prev.data.teamDetails[4] }] } })) }} />
                      {!email4 && <Warnmail><WarnIcon className="icon" /></Warnmail>}
                    </div>
                    <Input css={[highlight && (payload.data.teamDetails[3].year === "" && tw`border-red-600 border-2`)]} type="text" placeholder="Year" value={payload.data.teamDetails[3].year} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3], year: chx }, { ...prev.data.teamDetails[4] }] } })) }} />
                    <Input css={[highlight && (payload.data.teamDetails[3].course === "" && tw`border-red-600 border-2`)]} type="text" placeholder="Course" value={payload.data.teamDetails[3].course} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3], course: chx }, { ...prev.data.teamDetails[4] }] } })) }} />
                    <Input css={[highlight && (payload.data.teamDetails[3].branch === "" && tw`border-red-600 border-2`)]} type="text" placeholder="Branch" value={payload.data.teamDetails[3].branch} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3], branch: chx }, { ...prev.data.teamDetails[4] }] } })) }} />
                    <Input css={[highlight && (payload.data.teamDetails[3].collegeName === "" && tw`border-red-600 border-2`)]} type="text" placeholder="College Name" value={payload.data.teamDetails[3].collegeName} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3], collegeName: chx }, { ...prev.data.teamDetails[4] }] } })) }} />
                    <Input css={[highlight && (payload.data.teamDetails[3].mobile_no === "" && tw`border-red-600 border-2`)]} type="number" placeholder="Mobile No." value={payload.data.teamDetails[3].mobile_no} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3], mobile_no: chx }, { ...prev.data.teamDetails[4] }] } })) }} />
                    <Input css={[highlight && (payload.data.teamDetails[3].city === "" && tw`border-red-600 border-2`)]} type="text" placeholder="City" value={payload.data.teamDetails[3].city} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3], city: chx }, { ...prev.data.teamDetails[4] }] } })) }} />
                    <Input css={[highlight && (payload.data.teamDetails[3].state === "" && tw`border-red-600 border-2`)]} type="text" placeholder="State" value={payload.data.teamDetails[3].state} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3], state: chx }, { ...prev.data.teamDetails[4] }] } })) }} />
                    <Input css={[highlight && (payload.data.teamDetails[3].country === "" && tw`border-red-600 border-2`)]} type="text" placeholder="Country" value={payload.data.teamDetails[3].country} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3], country: chx }, { ...prev.data.teamDetails[4] }] } })) }} />
                  </Grid>
                  <Para>Member 5</Para>
                  <Grid>
                    <Input css={[highlight && (payload.data.teamDetails[4].fullName === "" && tw`border-red-600 border-2`)]} type="text" placeholder="Fullname" value={payload.data.teamDetails[4].fullName} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4], fullName: chx }] } })) }} />
                    <div tw="relative">
                      <Input css={[highlight && (payload.data.teamDetails[4].email === "" && tw`border-red-600 border-2`)]} type="email" onBlur={(ch) => { let chx = ch.target.value; setEmail5(mailCheck.test(chx)) }} placeholder="Email" value={payload.data.teamDetails[4].email} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4], email: chx }] } })) }} />
                      {!email5 && <Warnmail><WarnIcon className="icon" /></Warnmail>}
                    </div>
                    <Input css={[highlight && (payload.data.teamDetails[4].year === "" && tw`border-red-600 border-2`)]} type="text" placeholder="Year" value={payload.data.teamDetails[4].year} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4], year: chx }] } })) }} />
                    <Input css={[highlight && (payload.data.teamDetails[4].course === "" && tw`border-red-600 border-2`)]} type="text" placeholder="Course" value={payload.data.teamDetails[4].course} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4], course: chx }] } })) }} />
                    <Input css={[highlight && (payload.data.teamDetails[4].branch === "" && tw`border-red-600 border-2`)]} type="text" placeholder="Branch" value={payload.data.teamDetails[4].branch} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4], branch: chx }] } })) }} />
                    <Input css={[highlight && (payload.data.teamDetails[4].collegeName === "" && tw`border-red-600 border-2`)]} type="text" placeholder="College Name" value={payload.data.teamDetails[4].collegeName} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4], collegeName: chx }] } })) }} />
                    <Input css={[highlight && (payload.data.teamDetails[4].mobile_no === "" && tw`border-red-600 border-2`)]} type="number" placeholder="Mobile No." value={payload.data.teamDetails[4].mobile_no} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4], mobile_no: chx }] } })) }} />
                    <Input css={[highlight && (payload.data.teamDetails[4].city === "" && tw`border-red-600 border-2`)]} type="text" placeholder="City" value={payload.data.teamDetails[4].city} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4], city: chx }] } })) }} />
                    <Input css={[highlight && (payload.data.teamDetails[4].state === "" && tw`border-red-600 border-2`)]} type="text" placeholder="State" value={payload.data.teamDetails[4].state} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4], state: chx }] } })) }} />
                    <Input css={[highlight && (payload.data.teamDetails[4].country === "" && tw`border-red-600 border-2`)]} type="text" placeholder="Country" value={payload.data.teamDetails[4].country} onChange={(ch) => { let chx = ch.target.value; setPayload((prev) => ({ ...prev, data: { ...prev.data, teamDetails: [{ ...prev.data.teamDetails[0] }, { ...prev.data.teamDetails[1] }, { ...prev.data.teamDetails[2] }, { ...prev.data.teamDetails[3] }, { ...prev.data.teamDetails[4], country: chx }] } })) }} />
                  </Grid>
                  {(
                    payload.data.groupName === "" || payload.data.primaryContact === "" || payload.data.primaryEmail === "" || payload.data.topPreference.length === 0 ||
                    payload.data.teamDetails[0].fullName === "" || payload.data.teamDetails[0].email === "" || payload.data.teamDetails[0].course === "" || payload.data.teamDetails[0].branch === "" || payload.data.teamDetails[0].collegeName === "" || payload.data.teamDetails[0].mobile_no === "" || payload.data.teamDetails[0].city === "" || payload.data.teamDetails[0].state === "" || payload.data.teamDetails[0].year === "" || payload.data.teamDetails[0].country === "" ||
                    payload.data.teamDetails[1].fullName === "" || payload.data.teamDetails[1].email === "" || payload.data.teamDetails[1].course === "" || payload.data.teamDetails[1].branch === "" || payload.data.teamDetails[1].collegeName === "" || payload.data.teamDetails[1].mobile_no === "" || payload.data.teamDetails[1].city === "" || payload.data.teamDetails[1].state === "" || payload.data.teamDetails[1].year === "" || payload.data.teamDetails[1].country === "" ||
                    payload.data.teamDetails[2].fullName === "" || payload.data.teamDetails[2].email === "" || payload.data.teamDetails[2].course === "" || payload.data.teamDetails[2].branch === "" || payload.data.teamDetails[2].collegeName === "" || payload.data.teamDetails[2].mobile_no === "" || payload.data.teamDetails[2].city === "" || payload.data.teamDetails[2].state === "" || payload.data.teamDetails[2].year === "" || payload.data.teamDetails[2].country === "" ||
                    payload.data.teamDetails[3].fullName === "" || payload.data.teamDetails[3].email === "" || payload.data.teamDetails[3].course === "" || payload.data.teamDetails[3].branch === "" || payload.data.teamDetails[3].collegeName === "" || payload.data.teamDetails[3].mobile_no === "" || payload.data.teamDetails[3].city === "" || payload.data.teamDetails[3].state === "" || payload.data.teamDetails[3].year === "" || payload.data.teamDetails[3].country === "" ||
                    payload.data.teamDetails[4].fullName === "" || payload.data.teamDetails[4].email === "" || payload.data.teamDetails[4].course === "" || payload.data.teamDetails[4].branch === "" || payload.data.teamDetails[4].collegeName === "" || payload.data.teamDetails[4].mobile_no === "" || payload.data.teamDetails[4].city === "" || payload.data.teamDetails[4].state === "" || payload.data.teamDetails[4].year === "" || payload.data.teamDetails[4].country === "" ||
                    !pemail || !email1 || !email2 || !email3 || !email4 || !email5
                  ) ? <SubmitButton onClick={() => setHighlight(true)}>
                      <SubmitButtonIcon className="icon" />
                      <span className="text">{submitButtonText}</span>
                    </SubmitButton>
                    :
                    <>
                      {!process ? <ProcessButton disabled>
                        <Spinner />
                        <span className="text" tw="animate-pulse">Processing</span>
                      </ProcessButton> :
                        <SubmitButton onClick={register}>
                          <SubmitButtonIcon className="icon" />
                          <span className="text">{submitButtonText}</span>
                        </SubmitButton>
                      }
                    </>}

                  <p tw="mt-6 text-xs text-gray-600 text-center">
                    I agree to abide by Stackbyte's{" "}
                    <a href={tosUrl} tw="border-b border-gray-500 border-dotted">
                      Terms of Service
                  </a>{" "}
                  and its{" "}
                    <a href={privacyPolicyUrl} tw="border-b border-gray-500 border-dotted">
                      Privacy Policy
                  </a>
                  </p>

                  <p tw="mt-8 text-sm text-gray-600 text-center">
                    Already have an account?{" "}
                    <a href={signInUrl} tw="border-b border-gray-500 border-dotted">
                      Sign In
                  </a>
                  </p>
                </FormContainer>
              </MainContent>
            </MainContainer>
          }
          {/* <IllustrationContainer>
          <IllustrationImage imageSrc={illustrationImageSrc} />
        </IllustrationContainer> */}
        </Content>
      </Container>
    </AnimationRevealPage>
  )
};
