import axios from 'axios';

export const requestServer = (payload) => {
    let host = 'https://stackbyte.herokuapp.com/api/genericApi'

    return axios.post(host, JSON.stringify(payload),{ headers: { "Content-Type": "application/json; charset=UTF-8" } });
};

export const checkBlur = (gn) => {
    let host = 'https://stackbyte.herokuapp.com/api/genericApi'
    let payload = {
        "api_name": "getGroupName",
        "data": {
            "groupName" : gn
        }
    }

    return axios.post(host, JSON.stringify(payload),{ headers: { "Content-Type": "application/json; charset=UTF-8" } });
};

export const storeData = (key, value) => {
    // console.log("store", key, value)
   try {
       localStorage.setItem(key, JSON.stringify(value))
   } catch (e) {
       // saving error
   }
   return true;
}

export const getData = (key) => {
   //console.log("store", key)
   try {
       const value = JSON.parse(localStorage.getItem(key))
       if (value !== null) {
           return value;
           // value previously stored
       }
   } catch (e) {
       // saving error
   }
}