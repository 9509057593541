import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/lightUp.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColFixedWithButton.js";
import MainFeature2 from "components/features/TwoColWithButtonCustom.js";
import Mentor from "components/testimonials/TwoColumnWithImageCustom";
import Features from "components/features/ThreeColWithSideImageCustom";

// import SupportIconImage from "images/support-icon.svg";
// import ShieldIconImage from "images/shield-icon.svg";
// import CustomerLoveIconImage from "images/simple-icon.svg";

import ChooseMentor from "images/choose-a-mentor.jpg";
import DreamJob from "images/dream-Job.jpg";
import EnrollTeam from "images/enroll-team.jpg";
import GrabOpportunies from "images/grab-opportunies.jpg";
import Hackatons from "images/hackatons.jpg";
import Mentorship from "images/mentorship.jpg";
import ThreePlansPricing from "components/pricing/ThreePlansUp.js";
import vision from "../images/assets/vision.jpg";
const Container = tw.div`bg-white mx-0`;

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  // const [scrollDir, setScrollDir] = useState("scrolling down");

  // useEffect(() => {
  //   const threshold = 0;
  //   let lastScrollY = window.pageYOffset;
  //   let ticking = false;

  //   const updateScrollDir = () => {
  //     const scrollY = window.pageYOffset;

  //     if (Math.abs(scrollY - lastScrollY) < threshold) {
  //       ticking = false;
  //       return;
  //     }
  //     setScrollDir(scrollY > lastScrollY ? "scrolling down" : "scrolling up");
  //     lastScrollY = scrollY > 0 ? scrollY : 0;
  //     ticking = false;
  //   };

  //   const onScroll = () => {
  //     if (!ticking) {
  //       window.requestAnimationFrame(updateScrollDir);
  //       ticking = true;
  //     }
  //   };

  //   window.addEventListener("scroll", onScroll);

  //   return () => window.removeEventListener("scroll", onScroll);
  // }, []);

  // useEffect(() => {
  //   // console.log(scrollDir);
  //   if(scrollDir === 'scrolling up'){
  //     document.querySelector('.kFxrCQ').style.zIndex = 999
  //   }
  //   else{
  //     document.querySelector('.kFxrCQ').style.zIndex = 0
  //   }
  // }, [scrollDir]);

  // useEffect(() => {
  //   // setY(window.scrollY);
  //   localStorage.clear();
  // }, [])

  return (
    <AnimationRevealPage>
      <Header />

      <Container>
        <MainFeature1
          subheading={<Subheading>About Stackbyte</Subheading>}
          heading="India's First Ever Platform delivering guidance from professionals, leading industry experts as mentors and opportunities"
          description="StackByte bridges the daunting gap between academia and industry, and, brings you closer to your career goals."
          buttonRounded={false}
          primaryButtonText="Join Our Public Channel"
          imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
        />
        <MainFeature2
          subheading={<Subheading>Our Vision</Subheading>}
          heading="You are no more a Student! You are a Professional in the making!"
          description="Terms like experience and fresher have haunted us, too. Which is why we believe, that under the right guidance, your career can begin in college itself. You are no longer students, you are Professionals in making."
          buttonRounded={false}
          primaryButtonText="Join Our Public Channel"
          imageSrc={vision}
          textOnLeft={false}
        />
        <Features
          subheading={<Subheading>Our Values</Subheading>}
          heading="We follow these."
          description="Attend hackathons and e-workshops to gain hands-on experience and get internship opportunities"
          cards={[
            {
              imageSrc: EnrollTeam,
              title: "Enroll as team",
              description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
            },
            {
              imageSrc: ChooseMentor,
              title: "Choose your mentor",
              description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
            },
            {
              imageSrc: Mentorship,
              title: "Get mentorship and project guidance",
              description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
            },
            {
              imageSrc: Hackatons,
              title: "Participate in hackathons",
              description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
            },
            {
              imageSrc: GrabOpportunies,
              title: "Grab opportunities",
              description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
            },
            {
              imageSrc: DreamJob,
              title: "Get your dream job",
              description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
            },
          ]}
          linkText=""
        />
        <Mentor />
        <ThreePlansPricing description="Seek career advice from mentors in your chosen career field. Learn those skills from the live sessions with the expert professionals. Take periodic tests to validate your skills. Enroll as a team of 5." />
        {/* <TeamCardGrid 
        subheading={<Subheading>Our Team</Subheading>}
      /> */}
        <Footer />
      </Container>
    </AnimationRevealPage>
  );
};