import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import { css } from "styled-components/macro"; //eslint-disable-line
import Login from "./components/admin/adminLogin";
import Table from "./components/admin/Table";
import JoinPage from "pages/Joinus.js"; // custom page
import ProgramPage from "pages/Programs.js"; // custom page
import Checkout from "pages/Checkout"; // custom page
import Pricing from "pages/Price"; // custom page
import Success from "pages/Success"; // custom page
import AboutUsPage from "pages/About.js"; // custom page
import "react-awesome-lightbox/build/style.css";

import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

export default function App() {
  // return <AnimationRevealPage disabled></AnimationRevealPage>;
  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <AboutUsPage />
        </Route>
        <Route path="/admin" exact strict>
          <Login />
        </Route>
        <Route path="/details" exact strict>
          <Table />
        </Route>
        <Route path="/register">
          <JoinPage />
        </Route>
        <Route path="/pricing">
          <Pricing />
        </Route>
        <Route path="/thankyou">
          <Success />
        </Route>
        <Route path="/programs">
          <ProgramPage />
        </Route>
        <Route path="/checkout/:oid" exact strict>
          <Checkout />
        </Route>
        <Route exact path="/checkout/">
          <Redirect to="/" />
        </Route>
      </Switch>
    </Router>
  );
}
