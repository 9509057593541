import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import Select from 'react-select';
import axios from "axios";
import tw from "twin.macro";
import logo from "images/logo_s.svg";
import { Container as ContainerBase } from "components/misc/Layouts";

const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-9/12 p-6 sm:p-12`;
const Heading = tw.h1`text-2xl xl:text-3xl mt-2 font-extrabold`;

const Table = ({ headingText = "Welcome Admin" }) => {
    const LogoImage = tw.img`h-16 p-1 `;
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [name, setName] = useState();
    const [loading, setLoading] = useState(false);

    const selectOptions = [
        {
            label: "Student details", value: "getAllStudentDetails"
        },
        {
            label: "Payment details", value: "getAllPaymentDetails"

        }]

    const handleChange = selectedOption => {
        fetchData(selectedOption.value)
        setName(selectedOption.value)
    };

    const tableoptions = {
        filter: true,
        filterType: "dropdown",
        selectableRows: "none",
        responsive: "simple",
        //count: count,
        tableBodyHeight: "500px",
        tableBodyMaxHeight: "500px",
    };
    const fetchData = async (api_name) => {
        setLoading(true)
        try {
            const result = await axios.post("https://stackbyte.herokuapp.com/api/genericApi",
                { "api_name": api_name }
            )
            setLoading(false)
            setData(result.data.data)
            setColumns(result.data.title)
        }
        catch (error) {
            setData([])
            setColumns([])
        }
    };
    useEffect(() => {
        fetchData('getAllStudentDetails');
    }, []);

    return (
        <>
            <Container>
                <Content>
                    <MainContainer>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: '5px' }}>
                            <LogoImage src={logo} />
                            <Heading>{headingText}</Heading>
                        </div>
                        {/* <span style={{ color: '#363660', fontSize: '20px' }} className="pl-4 mt-4">
                            Get data for </span> */}
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', padding: '10px' }}>
                            <div style={{ width: '300px' }}>
                                <Select
                                    onChange={handleChange}
                                    defaultValue={[{ label: "Student details", value: "getAllStudentDetails" }]}
                                    options={selectOptions} />
                            </div>
                        </div>
                        {loading ? <div style={{ display: 'flex', justifyContent: 'center', padding: "50px" }}>
                            <div className="loader">
                            </div>
                        </div> :
                            <div style={{ padding: '50px' }}>
                                <MUIDataTable
                                    title={name === "getAllPaymentDetails" ? "Payment Details" : "Student Details"}
                                    data={data}
                                    columns={columns}
                                    options={tableoptions}
                                />
                            </div>
                        }
                    </MainContainer>
                </Content>
            </Container>
        </>
    )
}
export default Table;
