import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob } from "images/svg-decorator-blob-6.svg";
import { useHistory } from "react-router-dom";
import { storeData } from '../../services/request'
import megaByte from "../../images/assets/megaByte.png";
import gigaByte from "../../images/assets/gigaByte.png";
import teraByte from "../../images/assets/teraByte.png";
import Lightbox from "react-awesome-lightbox";

const TopContainer = tw(Container)`bg-white`;
const HeaderContainer = tw.div`mt-10 w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const PlansContainer = tw.div`flex justify-between flex-col lg:flex-row items-center lg:items-stretch relative`;
const Plan = styled.div`
  ${tw`w-full max-w-sm mt-16 lg:mr-8 lg:last:mr-0 text-center px-8 rounded-lg shadow relative pt-2 text-gray-900 bg-white flex flex-col`}
  .planHighlight {
    ${tw`rounded-t-lg absolute top-0 inset-x-0 h-2`}
  }

  ${props =>
    props.featured &&
    css`
      background: rgb(100,21,255);
      background: linear-gradient(135deg, rgba(100,21,255,1) 0%, rgba(128,64,252,1) 100%);
background: rgb(85,60,154);
background: linear-gradient(135deg, rgba(85,60,154,1) 0%, rgba(128,90,213,1) 100%);
background: rgb(76,81,191);
background: linear-gradient(135deg, rgba(76,81,191,1) 0%, rgba(102,126,234,1) 100%);
      ${tw`bg-primary-500 text-gray-100`}
      .planHighlight {
        ${tw`hidden`}
      }
      .duration {
        ${tw`text-gray-200!`}
      }
      ${PlanFeatures} {
        ${tw`border-indigo-500`}
      }
      .feature:not(.mainFeature) {
        ${tw`text-gray-300!`}
      }
      ${BuyNowButton} {
        ${tw`bg-gray-100 text-primary-500 hocus:bg-gray-300 hocus:text-primary-800`}
    `}
`;

const PlanHeader = styled.div`
  ${tw`flex flex-col uppercase leading-relaxed py-8`}
  .name {
    ${tw`font-bold text-xl`}
  }
  .price {
    ${tw`font-bold text-4xl sm:text-5xl my-1`}
  }
  .duration {
    ${tw`text-gray-600 font-bold tracking-widest`}
  }
`;
const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-8 border-t-2 border-b-2 flex-1`}
  .feature {
    ${tw`mt-5 first:mt-0 font-medium`}
    &:not(.mainFeature) {
      ${tw`text-gray-800`}
    }
  }
  .mainFeature {
    ${tw`text-lg font-bold tracking-wide`}
  }
`;

const PlanAction = tw.div`px-4 sm:px-8 xl:px-16 py-8`;
const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`rounded-full uppercase tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
`;

const DecoratorBlob = styled(SvgDecoratorBlob)`
  ${tw`pointer-events-none z-10 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-1/2 translate-y-1/2`}
`;

export default ({
  subheading = "Pricing",
  heading = "Flexible Plans.",
  description = "Seek career advice from mentors in your chosen career field. Learn those skills from the live sessions with the expert professionals. Take periodic tests to validate your skills. Enroll as a team of 5.",
  plans = null,
  primaryButtonText = "Join Now"
}) => {
  let history = useHistory();
  const defaultPlans = [
    {
      name: "Megabyte",
      price: 600,
      duration: "Monthly",
      mainFeature: "Suited for All the Students, kickstarting their careers",
      features: [
        "1 Mentor per group", 
        "Career Profiling", 
        "1 online interaction session / week", 
        "1 dedicated workshop",
        "Hackathons",
        "E-mail support",
        "Resume Building",
        "Career Assessment Report",
        "Career Guidance"
      ],
    },
    {
      name: "Gigabyte",
      price: 1200,
      duration: "Monthly",
      mainFeature: "Suited for students from 2nd and 3rd Year",
      features: [
        "Upto 3 mentors per group", 
        "Career Profiling", 
        "2 online interaction session / week", 
        "2 Workshops",
        "Hackathons", 
        "E-mail and Chat support", 
        "Resume Building",
        "Professional Network Building", 
        "Career Assessment Report", 
        "Internship Assistance",
        "Career Guidance"
      ],
      featured: true,
    },
    {
      name: "Terabyte",
      price: 2400,
      duration: "Monthly",
      mainFeature: "Best Suited for students in pre-final and final year's",
      features: [
        "Upto 5 Mentors per group", 
        "Career Profiling", 
        "4 online interaction session / week", 
        "1 soft skills training session",
        "3 Workshops",
        "Hackathons",
        "E-mail and Chat support",
        "Resume Building",
        "Professional Network Building",
        "Career Assessment Report",
        "Career Guidance",
        "Job and Internship Assistance"
      ],
    },
  ];

  if (!plans) plans = defaultPlans;

  const highlightGradientsCss = [
    css`
      background: rgb(56, 178, 172);
      background: linear-gradient(115deg, rgba(56, 178, 172, 1) 0%, rgba(129, 230, 217, 1) 100%);
    `,
    css`
      background: rgb(56, 178, 172);
      background-image: linear-gradient(115deg, #6415ff, #7431ff, #8244ff, #8e56ff, #9a66ff);
    `,
    css`
      background: rgb(245, 101, 101);
      background: linear-gradient(115deg, rgba(245, 101, 101, 1) 0%, rgba(254, 178, 178, 1) 100%);
    `
  ];

  const [show, setShow] = React.useState();
  const [text, setText] = React.useState();

  const openLightBox = (plan) => {
    setText(plan)
    setShow(true);
  }

  return (
    <TopContainer>
      <ContentWithPaddingXl>
        <HeaderContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <Heading>{heading}</Heading>
          {description && <Description>{description}</Description>}
        </HeaderContainer>
        <PlansContainer>
          {plans.map((plan, index) => (
            <Plan key={index} featured={plan.featured}>
              {!plan.featured && <div className="planHighlight" css={highlightGradientsCss[index % highlightGradientsCss.length]} />}
              <PlanHeader>
                <span className="name">{plan.name}</span>
                <span className="price">{plan.price} INR</span> 
                <span style = {{fontSize: '12px'}}>Per Group</span>
                <span className="duration">{plan.duration}</span>
              </PlanHeader>
              <PlanFeatures>
                <span className="feature mainFeature">{plan.mainFeature}</span>
                {plan.features.map((feature, index) => (
                  <span key={index} className="feature">
                    {feature}
                  </span>
                ))}
                
              </PlanFeatures>
              <div style={{ padding: '5px' }}>
                  <span style={{ cursor: 'pointer', fontWeight: 'bold', textDecoration:'underline'}} onClick={() => openLightBox(plan.name)}> Read More </span>
                </div>
              <PlanAction>
                <BuyNowButton css={!plan.featured && highlightGradientsCss[index]} onClick={async () => { await storeData("price", plan.price * 100); await history.push('/register') }}>{primaryButtonText}</BuyNowButton>
                
              </PlanAction>
            </Plan>
          ))}
          <DecoratorBlob />
        </PlansContainer>
        {show ?
          <Lightbox
            image={text === "Megabyte" ? megaByte : text === "Gigabyte" ? gigaByte : teraByte}
            showTitle={false}
            onClose={() => setShow(false)}
          />
          : null}
      </ContentWithPaddingXl>
    </TopContainer>
  );
};
