import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const Login = (props) => {
    const [error, setError] = useState(false);
    const classes = useStyles();

    let validation = yup.object().shape({
        email: yup.string().email("Enter a valid email").required("This field is required"),
        password: yup.string().required("This field is required"),
    });

    let formik = useFormik({
        initialValues: { email: "", password: "" },
        validationSchema: validation,
        onSubmit: (values) => {
            handleSubmit(values);
        },
    });

    const handleSubmit = async (val) => {
        getLogin(val)
        formik.setSubmitting(false);
    }

    const getLogin = (val) => {
        if (val.email === "admin@stackbyte.in" && val.password === "admin") {
            props.history.push('/details')
        }
        else {
            setError(true)
        }
    }

    return (
        <Grid direction="column" alignItems="center" justify="center" style={{ minHeight: '100vh' }} spacing={0} container component="main" className="login-grid">
            <CssBaseline />
            <Grid component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOpenIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Admin Login
                    </Typography>
                    <form onSubmit={formik.handleSubmit}>
                        <TextField
                            error={formik.errors.email && formik.touched.email}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            autoFocus
                            helperText={
                                formik.errors.email && formik.touched.email
                                    ? formik.errors.email
                                    : null} />
                        <TextField
                            error={formik.errors.password && formik.touched.password}
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            helperText={
                                formik.errors.password && formik.touched.password
                                    ? formik.errors.password
                                    : null} />
                        <div style={{ textAlign: 'center' }}>
                            {error ? <span style={{ color: 'red' }}>Invalid Credentials</span> : null}
                        </div>
                        <Button
                            type="submit"
                            disabled={formik.isSubmitting}
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}>
                            Sign In
                        </Button>
                    </form>
                </div>
            </Grid>
        </Grid>
    );
}

export default withRouter(Login);