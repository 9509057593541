import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import { ReactComponent as QuotesLeftIcon } from "../../images/quotes-l.svg";
import { ReactComponent as QuotesRightIcon } from "../../images/quotes-r.svg";
import { ReactComponent as ArrowLeftIcon } from "../../images/arrow-left-2-icon.svg";
import { ReactComponent as ArrowRightIcon } from "../../images/arrow-right-2-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-4.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-5.svg";

// import Favatar from "../../images/favatar.png";
import Mavatar from "../../images/mavatar.png";

import "slick-carousel/slick/slick.css";

const Container = tw.div`relative bg-white`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const TestimonialSliderContainer = tw.div`mt-24`;
const TestimonialSlider = styled(Slider)``;
const Testimonial = tw.div`flex! flex-col items-center justify-center outline-none`;
const ImageContainer = styled.div`
  ${tw`md:mx-3 lg:mx-6 w-2/3 md:w-4/12 rounded flex items-center max-w-xs md:max-w-none`}
  img {
    ${tw`rounded`}
  }
`;
const TextContainer = tw.div`p-4 flex flex-col justify-between`;
const QuoteContainer = tw.div`relative p-6 mt-4`;
const Quote = tw.blockquote`text-center font-medium text-xl`;
const CustomerInfo = tw.div`px-5 text-center mt-4`;
const CustomerName = tw.h5`font-bold text-lg text-primary-500`;
const CustomerTitle = tw.p`font-medium text-sm`;

const QuotesLeft = tw(QuotesLeftIcon)`w-8 h-8 lg:w-10 lg:h-10 text-primary-500 absolute top-0 left-0`;
const QuotesRight = tw(QuotesRightIcon)`w-8 h-8 lg:w-10 lg:h-10 text-primary-500 absolute bottom-0 right-0`;

const SliderControlButtonContainer = styled.div`
  ${tw`absolute top-0 h-full flex items-end z-20`}
  button {
    ${tw`px-2 py-1 text-secondary-500 hover:text-primary-500 focus:outline-none transition duration-300 transform hover:scale-125 transform -translate-y-2/3 md:translate-y-0`}
    svg {
      ${tw`w-8`}
    }
  }
`;

const NextArrow = ({ currentSlide, slideCount, ...props }) => (
  <SliderControlButtonContainer tw="right-0">
    <button {...props}>
      <ArrowRightIcon />
    </button>
  </SliderControlButtonContainer>
);
const PreviousArrow = ({ currentSlide, slideCount, ...props }) => (
  <SliderControlButtonContainer tw="left-0">
    <button {...props}>
      <ArrowLeftIcon />
    </button>
  </SliderControlButtonContainer>
);

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute w-32 top-0 left-0 z-10 text-primary-500 opacity-25 transform -translate-x-full`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`absolute w-32 bottom-0 right-0 z-10 text-pink-500 opacity-15 transform translate-x-2/3 translate-y-8`;

export default () => {
  /*
   * You can modify the testimonials shown by modifying the array below
   * You can add or remove objects from the array as you need.
   */
  const testimonials = [
    {
      imageSrc:
        Mavatar,
      quote:
        "Over 6 years of experience across Retail,Telecom,Avition,Automotive domains. His expertise include Java, Javascript, Bigdata, Cloud Services and IOT",
      customerName: "Sridharan Murugadass",
      customerTitle: "Full Stack Architect"
    },
    {
      imageSrc:
        Mavatar,
      quote:
        "Having 12+ years of experience. His Domains are Retail, Telecom, E-commerce, Education. His known technologies includes Java, Selenium, Automation, Cloud Services ",
      customerName: "Dinesh Chengalvarayan",
      customerTitle: "Automation Architect"
    },
    {
      imageSrc:
        Mavatar,
      quote:
        "Having 11+ years of experience. His domains includes Retail, Telecom and E-commerce.Technologies he is expertise are Java, NodeJs, Python & Cloud Services",
      customerName: "Moulidaren dinakaran",
      customerTitle: "Applications and Data Architect"
    },
    {
      imageSrc:
        Mavatar,
      quote:
        "Over 8+ years of experience in Software quality assurance across various domains such as Casino slot gaming, Trading platforms, Healthcare, Logistics, Aviation & Video platforms (OTT). He is known to technologies such as JavaScript, Perl, Java, Ruby, Python & cloud services.",
      customerName: "Saranraj Prabhakaran",
      customerTitle: "Cloud Automation Engineering"
    },
    {
      imageSrc:
        Mavatar,
      quote:
        "Having 7+ years of experience in domains such as Retail, Telecom and E-commerce. Technologies includes Java, Spring, Cloud Services & Bigdata",
      customerName: "VinothKumar Chandran",
      customerTitle: "Big Data Engineer"
    },
    {
      imageSrc:
        Mavatar,
      quote:
        "Having 5+ years of experience in domains such as Telecom, IoT and Marketing. His area of expertise are Backend development, Automation Testing, Data analysis and BI",
      customerName: "Shri Krishna",
      customerTitle: "Full Stack Engineer"

    },
    {
      imageSrc:
        Mavatar,
      quote:
        "Having 3+ years of experience. His domains are Education, IoT and Logistics. His Area of expertise are Backend development, Nosql, Javascript and cloud",
      customerName: "Nirav Parmar",
      customerTitle: "Software Engineer"

    },
    {
      imageSrc:
        Mavatar,
      quote:
        "Having 3+ years of experience and worked in domains such as Energy, IoT & Education. His Area of expertise are customer relations, business development, project management and data analytics",
      customerName: "Sameer Sanjeevi",
      customerTitle: "Product Manager"

    }
  ];
  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          <HeadingTitle>Our Top Mentors</HeadingTitle>
          <HeadingDescription></HeadingDescription>
        </HeadingInfoContainer>
        <TestimonialSliderContainer>
          <TestimonialSlider slidesToShow={3} slidesToScroll={3} responsive={[{ breakpoint: 1024, settings: { slidesToShow: 1, slidesToScroll: 1 } }]} nextArrow={<NextArrow />} prevArrow={<PreviousArrow />}>
            {testimonials.map((testimonial, index) => (
              <Testimonial key={index}>
                <ImageContainer>
                  <img src={testimonial.imageSrc} alt={testimonial.customerName} />
                </ImageContainer>
                <TextContainer>
                  <QuoteContainer>
                    <QuotesLeft />
                    <Quote>{testimonial.quote}</Quote>
                    <QuotesRight />
                  </QuoteContainer>
                  <CustomerInfo>
                    <CustomerName>{testimonial.customerName}</CustomerName>
                    <CustomerTitle>{testimonial.customerTitle}</CustomerTitle>
                  </CustomerInfo>
                </TextContainer>
              </Testimonial>
            ))}
          </TestimonialSlider>
        </TestimonialSliderContainer>
      </Content>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
