import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/lightUp.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import ThreePlansPricing from "components/pricing/ThreePlansUp.js";

// const Subheading = tw.span`uppercase tracking-wider text-sm`;
const Container = tw.div`mb-12 relative`;

export default () => {
  // useEffect(() => {
  //   localStorage.removeItem("price");
  // },[])

  return (
    <AnimationRevealPage>
      <Header />
      {/* <TeamCardGrid 
        subheading={<Subheading>Our Team</Subheading>}
      /> */}
      <Container>
      <ThreePlansPricing />
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};